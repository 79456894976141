import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { FaLessThan } from 'react-icons/fa';
import QuestionGroup from "./QuestionGroup";
import Button from "reactstrap/lib/Button";
import CommunicationComponent from "../communication/CommunicationComponent";

class QuestionPageContainer extends CommunicationComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    qCtrl() {
        return this.props.qctrl;
    }


    jumpToTop() {
        if (typeof document !== "undefined") {
            document.getElementById("questionwrapper").scrollTo({top: 0, behavior: "auto"});
        }
    }

    next() {
        this.fireEvent("pageChanged");
        const errors = this.qCtrl().checkForAnswersBeforeStep();
        if (errors == null) {
            this.qCtrl().nextQuestionPage(() => {
                this.jumpToTop();
            });
        }
    }

    summary(e) {
        this.fireEvent("pageChanged");
        const errors = this.qCtrl().checkForAnswersBeforeStep();
        if (errors == null) {
            this.props.onResultsPageClicked(e);
        }
    }

    back(e) {
        this.fireEvent("pageChanged");
        this.qCtrl().stepBack(() => {
            this.jumpToTop();
        });
    }

    isLastQuestion() {
        return this.qCtrl().isCurrentLastQuestion();
    }

    render() {
        return <div className={"questioncontainer p-0"} fluid={true}>
            <div>
                <div md={12}>
                    <QuestionGroup {...this.props}></QuestionGroup>
                </div>
                <div className={"col-md-5 footer-container"}>
                    <hr/>
                    <div>
                        <div sm={6}>
                            <button onClick={(e) => this.back(e)} className={"back-button"} color={"None"}>&lt; Zurück</button>
                        </div>
                        <div sm={6} >
                            {!this.isLastQuestion() && <button  onClick={(e) => this.next(e)} color={"primary"}  className={"float-right"}>Weiter</button>}
                            {this.isLastQuestion() && <button  onClick={(e) => this.summary(e)} color={"primary"}  className={"float-right"}>Zusammenfassung</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
            ;
    }
};

QuestionPageContainer.propTypes = {

};


export default QuestionPageContainer;
