import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from './QuestionType';
import Input from 'reactstrap/lib/Input';
//import { SketchPicker, CompactPicker, CirclePicker, PhotoshopPicker, MaterialPicker, SwatchesPicker, TwitterPicker, HuePicker, ChromePicker, BlockPicker } from 'react-color';
import { ChromePicker } from 'mp-react-color';
import EyeDropper from './EyeDropper';

class QuestionColor extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTextValue() {
    const txt = this.getValue();
    if (txt == null || typeof txt === 'undefined') {
      return '#ccc';
    }
    return txt;
  }

  render() {
    return (
      <div
        fluid={true}
        className={'color-text-container ' + (this.hasError() ? 'error' : '')}
      >
        <div>
          <div className={'p-1'} md={12}>
            {!this.isFinished() && (
              <div>
                <ChromePicker
                  disableEyedropper
                  disableAlpha
                  rootElement={document.querySelector('#app')}
                  color={this.getTextValue()}
                  onChangeComplete={(color) => this.setValue(color.hex)}
                />
                <EyeDropper
                  onColorSelected={(c) => this.setValue(c)}
                ></EyeDropper>
              </div>
            )}
            {this.isFinished() && (
              <div
                className={'color-block'}
                style={{ backgroundColor: this.getTextValue() }}
              >
                {this.getTextValue()}
              </div>
            )}
            {this.renderErrors()}
          </div>
        </div>
      </div>
    );
  }
}

QuestionColor.propTypes = {};

export default QuestionColor;
