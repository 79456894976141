import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaHistory } from 'react-icons/fa';
import QuestionPageContainer from './QuestionPageContainer';
import Lightbox from 'react-image-lightbox';
import '!style-loader!css-loader!react-image-lightbox/style.css';
// Import the Slate components and React plugin.
// Import the Slate editor factory.
import Lottie from 'react-lottie';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import '!style-loader!css-loader!react-quill/dist/quill.snow.css';
import Button from 'reactstrap/lib/Button';
import ImageNumberMapping from '../specials/ImageNumberMapping';
import { IMAGE_URL } from '../../config';
import { sendFullWebsiteUpdate } from "../../helper/helper";
let Inline = Quill.import('blots/inline');
class ReplaceBlot extends Inline {}
ReplaceBlot.blotName = 'replace';
ReplaceBlot.tagName = 'replace';
Quill.register('formats/replace', ReplaceBlot);

const formats = ['h1']; // add custom format name + any built-in formats you need

class SamplePageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      refreshIframe: false,
      currentUrl: null,
      loading: true,
      isOpen: false,
    };
  }

  getOptions(q) {
    return q['options'];
  }

  toggleOpen(clickedImage, q) {
    const index = this.getAllImagesByQuestions().findIndex(
      (d) => d === clickedImage
    );
    this.setState({ isOpen: true, photoIndex: index });
  }

  qCtrl() {
    return this.props.qctrl;
  }

  renderGridImages(multiimages, q) {
    try {
      let images = [];
      if (multiimages.length > 0) {
        for (let i = 0; i < multiimages.length; i++) {
          try {
            const img = multiimages[i];
            if (typeof img === 'undefined') continue;
            images.push(
              <div
                className={'sample-image-container-small'}
                onClick={(e) => this.toggleOpen(img, q)}
                md={2}
                sm={6}
              >
                <ImageNumberMapping>
                  <img className={'small-grid-image'} src={IMAGE_URL + img} />
                </ImageNumberMapping>
              </div>
            );
          } catch (e) {}
        }
        return (
          <div className={'sample-image-container-grid'}>
            <div>{images}</div>
          </div>
        );
      }
    } catch (e) {
      console.error('Can not render sample images');
    }
    return null;
  }

  getCurrentTextOption() {
    const val = this.qCtrl().getValueForQuestion(q);
    if (val == null) {
      return null;
    }
    const option = this.qCtrl().getOptionByName(val);
    if (option == null) {
      return null;
    }
    return option;
  }

  onEditorStateChange(section, val, option, question) {
    section = parseInt(section.toString());
    const text = this.qCtrl().hasAdditionalData(question.id)
      ? this.qCtrl().getAdditionalData(question)
      : option['Beispiel-Text'];
    const splitText = text.replaceAll('\n', '');
    let sections = splitText.match(/<section.*?<\/section/g);
    if (sections == null || sections.length == 0) {
      sections = [val];
    } else {
      sections = sections.map((s) => {
        const number = s.match(/(\d+)/g)[0];
        return s + number + '>';
      });
      if (typeof sections[section] !== 'undefined') {
        const curSection = sections[section];
        const number = curSection.match(/(\d+)/g)[0];
        sections[section] =
          curSection.match(/<section.*?>/g)[0] +
          val +
          '</section' +
          number +
          '>';
      }
    }

    //if (val != option["Beispiel-Text"]) {
    this.qCtrl().appendAdditionalValue(question, sections.join(''));
    //}
  }

  resetText(option, question) {
    this.qCtrl().appendAdditionalValue(question, option['Beispiel-Text']);
  }

  renderText(option, question) {
    // Check Type
    const design = question['design'] ? question['design'].id : null;
    // Design can be: "grid" oder "untereinander"
    const text = this.qCtrl().hasAdditionalData(question.id)
      ? this.qCtrl().getAdditionalData(question)
      : option['Beispiel-Text'];
    let rtfText = text;

    if (typeof rtfText === 'undefined' || rtfText == null) {
      return null;
    }

    try {
      if (!this.qCtrl().isFinished() || this.qCtrl().isEditMode()) {
        const _this = this;
        // Split to rtf Text in different parts
        let index = 0;
        const splitText = rtfText.replaceAll('\n', '');
        let sections = splitText.match(/<section.*?<\/section/g);
        if (sections == null || sections.length == 0) {
          sections = [rtfText];
        } else {
          sections = sections.map((s) => {
            return s
              .replace(/<section(.*?)\>/g, '')
              .replace(/<\/section(.*?)/g, '');
          });
        }
        const quillEditors = [];
        for (const i in sections) {
          const s = sections[i];
          quillEditors.push(
            <ReactQuill
              id={'quill' + i}
              theme="snow"
              value={s}
              onChange={(text, delta, source, editor) => {
                if (source == 'user') {
                  // place whatever function you want to execute when user types here:
                  this.onEditorStateChange(i, text, option, question);
                }
              }}
            />
          );
        }

        return (
          <div>
            <button
              onClick={(e) => this.resetText(option, question)}
              color={'None'}
              className={'react-quill-reset-btn'}
            >
              <FaHistory size={12} /> Text zurücksetzen
            </button>
            {quillEditors}
          </div>
        );
      } else {
        return (
          <div>
            <span dangerouslySetInnerHTML={{ __html: rtfText }}></span>
          </div>
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  renderImages(q, parentQuestion) {
    try {
      if (Array.isArray(q)) {
        const list = q.filter((qs) => qs != null);

        // Check Type
        const design = parentQuestion['design']
          ? parentQuestion['design'].id
          : null;

        if (design == null || design.toLowerCase() === 'untereinander') {
          const retlist = list.map((qs) => {
            if (qs != null) {
              // Design can be: "grid" oder "untereinander"
              let bild = qs['Beispiel-Bild'];

              if (bild != null && !Array.isArray(bild)) {
                bild = [bild];
              }

              if (bild != null && Array.isArray(bild)) {
                let retList = [];
                bild
                  .filter((r) => {
                    if (typeof r !== 'undefined') {
                      if (typeof r.url === 'undefined') return false;
                      return true;
                    }
                    return false;
                  })
                  .map((b) => {
                    if (typeof b !== 'string') {
                      b = b.url;
                    }
                    retList.push(
                      <div
                        onClick={(e) => this.toggleOpen(b, qs)}
                        className={'col-md-6 sample-image-container-small'}
                      >
                        <img src={IMAGE_URL + b} />
                      </div>
                    );
                    return b;
                  });
                return retList;
              }
            }
          });
          return <div>{retlist}</div>;
        } else {
          const filteredList = list
            .filter(
              (f) =>
                typeof f !== 'undefined' &&
                f != null &&
                f['Beispiel-Bild'] != null
            )
            .flatMap((f) => f['Beispiel-Bild'])
            .map((e) => {
              return e.url;
            });
          return this.renderGridImages(filteredList, parentQuestion);
        }
      } else {
        // Check Type
        const design = parentQuestion['design']
          ? parentQuestion['Design'].id
          : null;
        // Design can be: "grid" oder "untereinander"
        const bild = q['Beispiel-Bild'];

        if (Array.isArray(bild)) {
          // Its a multi image
          const multiimages = bild;
          const images = [];
          if (design == null || design.toLowerCase() === 'untereinander') {
            for (let img of multiimages) {
              if (typeof img === 'undefined') continue;
              if (typeof img !== 'string') {
                if (typeof img.url === 'undefined') continue;
                img = img.url;
              }
              images.push(
                <div
                  onClick={(e) => this.toggleOpen(img, q)}
                  className={'sample-image-container'}
                >
                  <img src={IMAGE_URL + img} />
                </div>
              );
            }
          } else {
            return this.renderGridImages(multiimages, q);
          }
          return images;
        } else {
          let img = bild;
          if (typeof bild !== 'undefined') {
            if (typeof bild !== 'string') {
              img = bild.url;
            }
            return (
              <div
                onClick={(e) => this.toggleOpen(img, q)}
                className={'sample-image-container'}
              >
                <img src={IMAGE_URL + img} />
              </div>
            );
          }
          return null;
        }
      }
    } catch (e) {
      return null;
    }
  }

  renderSelectedText(q) {
    const val = this.qCtrl().getValueForQuestion(q);
    if (val == null) {
      return null;
    }
    const option = this.qCtrl().getOptionByName(val);
    if (option == null) {
      return null;
    }
    return this.renderText(option, q);
  }

  renderSelectedImage(q) {
    const val = this.qCtrl().getValueForQuestion(q);
    if (val == null) {
      return null;
    }
    const option = this.qCtrl().getOptionByName(q, val);
    if (option == null) {
      return null;
    }
    return this.renderImages(option, q);
  }

  renderSamplesForQuestionsVisible() {
    const list = [];
    const questions = this.qCtrl().getCurrentQuestionsForPage();
    for (const q of questions) {
      if (this.qCtrl().checkCondition(q)) {
        try {
          if (q.Datentyp.toLowerCase().includes('select')) {
            list.push(this.renderSelectedImage(q));
            list.push(this.renderSelectedText(q));
          }
        } catch (e) {}
        try {
          if (q['Beispiel-Bild']) {
            list.push(this.renderImages(q, q));
          }
        } catch (e) {}
      }
    }
    return list;
  }

  getImagesForAllOptions(q) {
    const list = [];
    this.getOptions(q).map((d) => {
      if (d['Beispiel-Bild']) {
        if (Array.isArray(d['Beispiel-Bild'])) {
          const bild = d['Beispiel-Bild'];
          if (bild) {
            list.push(...bild.map((d) => d.url));
          }
        } else {
          const bild = d['Beispiel-Bild'];
          if (bild) {
            list.push(bild.url);
          }
        }
      }
    });
    return list;
  }

  getAllImagesByQuestions() {
    let list = [];
    const questions = this.qCtrl().getCurrentQuestionsForPage();
    for (const q of questions) {
      try {
        if (this.qCtrl().checkCondition(q)) {
          if (q.Datentyp.toLowerCase().includes('select')) {
            list.push(...this.getImagesForAllOptions(q));
          } else if (q['Beispiel-Bild']) {
            list.push(...q['Beispiel-Bild'].map((d) => d.url));
          }
        }
      } catch (e) {}
    }

    list = list
      .filter((f) => typeof f !== 'undefined' && f != null)
      .map((m) => m.replace('"', ''));

    return list;
  }

  renderLightbox() {
    const images = this.getAllImagesByQuestions();

    const { photoIndex, isOpen } = this.state;

    return (
      isOpen && (
        <Lightbox
          mainSrc={IMAGE_URL + images[photoIndex]}
          nextSrc={IMAGE_URL + images[(photoIndex + 1) % images.length]}
          prevSrc={
            IMAGE_URL + images[(photoIndex + images.length - 1) % images.length]
          }
          onCloseRequest={() => this.setState({ isOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length,
            })
          }
        />
      )
    );
  }

  onIframeLoading(e) {

    this.setState({loading: false});
    sendFullWebsiteUpdate(this.qCtrl().formular.state.website, this.qCtrl());
  }

  renderWebsitePreviewIfNeeded() {
    if (this.qCtrl().formular.state.websitePreview != null) {
      let targetUrl = null;
      const url = this.qCtrl().formular.state.websitePreview.previewURL
        ? this.qCtrl().formular.state.websitePreview.previewURL
        : '';
      if (url.startsWith('http')) {
        targetUrl = url;
        if (url.includes("?")) {
          targetUrl = url + "&websiteconfig=true";
        }
        else {
          targetUrl = url + "?websiteconfig=true";
        }
      } else {
        let hostname = window.location.hostname;
        if (hostname.includes('localhost')) {
          hostname = 'localhost:8008';
        } else {
          hostname = window.location.hostname.split('.').slice(1).join('.');
        }
        targetUrl =
          window.location.protocol +
          '//dev.' +
          hostname + "/webconfigpreview" +
          '?preview=' +
          this.qCtrl().getId() +
          '&t=' +
          this.props.reloadTime+
          "&zoomLevel="+window.devicePixelRatio+
          "&websiteconfig=true";
      }
      if (this.state.currentUrl != targetUrl) {
        this.state.currentUrl = targetUrl;
        this.setState({refreshIframe: true, loading: true, currentUrl: targetUrl});
        setTimeout(() => this.setState({refreshIframe: false}), 200);
      }


      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('./loading.json'),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

      const factor = window.devicePixelRatio;

      return (
        <div className={"iframe-container"}>
          {this.state.loading && <div className={"iframe-loader"}>
            <div className={"iframe-content-loader"}>
              <div>
                <Lottie options={defaultOptions}
                        height={400}
                        width={400}
                        isStopped={this.state.isStopped}
                        isPaused={this.state.isPaused}/>
              </div>
              <div className={"iframe-content-loader-text"}>
                Webseitenvorschau wird generiert
              </div>
            </div>
          </div>}
          {!this.state.refreshIframe && <iframe id={"website-preview-frame"}
                  style={{
                    border: "2px solid #ccc",
                    borderRadius: "3px",
                    width: "calc(100%*"+(factor)+")",
                    height: "calc(70vh*"+(factor)+")",
                    backgroundColor: "white",
                    minHeight: "calc(400px*"+(factor)+")",
                    "-webkit-transform": "scale("+(1/factor)+")",
                    "-webkit-transform-origin": "0 0",
                    msTransform: "scale("+(1/factor)+")",
                    msTransformOrigin: "0 0 ",
                  }}
                  onLoad={(e) => this.onIframeLoading(e)}
                  className={'website-preview'}
                  src={targetUrl} />}
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div fluid={true}>
        <div>
          <div className={'p-1'} md={12}>
            {this.renderSamplesForQuestionsVisible()}
            {this.renderWebsitePreviewIfNeeded()}
            {this.renderLightbox()}
          </div>
        </div>
      </div>
    );
  }
}

SamplePageContainer.propTypes = {};

export default SamplePageContainer;
