import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { FaLessThan } from 'react-icons/fa';

class ProjectNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getCategories() {
        return this.props.categories;
    }

    qCtrl() {
        return this.props.qctrl;
    }

    getCustomerNameFromZoho() {
        const zohoCustomerData = this.qCtrl().getZohoCustomerData();
        if (zohoCustomerData) {
            return zohoCustomerData["company"] + " - "+zohoCustomerData["firstname"] + " " + zohoCustomerData["surname"];
        }
        return null;
    }

    showResultsPage(e) {
        this.props.onResultsPageClicked(e);
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    render() {
        return <div className={"grid grid-cols-12 p-1"} fluid={true}>
                <div className={"col-span-6 justify-start flex"}>
                    <FaLessThan size={14} /> Kunde: {this.getCustomerNameFromZoho()} ({this.props.name})
                </div>
                <div className={"col-span-6 justify-end flex"}>
                    <a href={"#"} onClick={(e) => this.showResultsPage(e)}>Zusammenfassung</a>
                </div>
            </div>
        ;
    }
};

ProjectNavigation.propTypes = {

};


export default ProjectNavigation;
