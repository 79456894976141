import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QuestionType from './QuestionType';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import { withTranslation } from 'react-i18next';

class QuestionMultipleChoice extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {
      update: this.state.update,
      options: null,
    };
  }

  componentDidMount() {
    this.loadOptions();
  }

  updateFields() {
    this.loadOptions();
  }

  findOptionsInOptionPath(option_id, parentOptions = null) {
    if (!parentOptions) {
      parentOptions = this.state.options;
    }
    for (const p of parentOptions) {
      if (p.id === option_id) {
        return [p];
      }
      if (p.children) {
        const parent_e = this.findOptionsInOptionPath(option_id, p.children);
        if (parent_e.length > 0) {
          return [...parent_e, p];
        }
      }
    }
    return [];
  }

  findOptionById(option_id, options = null) {
    if (!options) {
      options = this.state.options;
    }
    for (const p of options) {
      if (this.easyCompare(p.id, option_id)) {
        return p;
      }
      if (p.children) {
        const parent_p = this.findOptionById(option_id, p.children);
        if (parent_p) {
          return parent_p;
        }
      }
    }
    return null;
  }

  hasChildOptionsSelected(option_id, options = null) {
    if (!options) {
      const first_option = this.findOptionById(option_id);
      if (typeof first_option === 'object' && first_option.children) {
        options = first_option.children;
      } else {
        options = [];
      }
    }
    for (const p of options) {
      if (this.hasValue(p.id) && p.id != option_id) {
        return true;
      }
      if (p.children) {
        if (this.hasChildOptionsSelected(p.id, p.children)) {
          return true;
        }
      }
    }

    return false;
  }

  removeValue(value) {
    // When there are options in childs clicked - deselection is not possible
    if (this.hasChildOptionsSelected(value)) {
      return false;
    }

    return super.removeValue(value);
  }

  addValue(value) {
    const parentQuestions = this.findOptionsInOptionPath(value);

    for (const p of parentQuestions) {
      super.addValue(p.id.toLowerCase());
    }

    return super.addValue(value);
  }

  onOptionChanged(e, option) {
    // If the value before was "null"
    // then we now - add all the vorauswahl elements here
    if (this.getValue() == null) {
      const vorauswahl = this.qCtrl().getVorauswahlforQuestion(this.question());
      if (vorauswahl) {
        if (Array.isArray(vorauswahl)) {
          for (const v of vorauswahl) {
            if (typeof v === 'string') {
              this.addValue(v.toLowerCase());
            }
          }
        }
      }
    }

    if (e.target.checked) {
      this.addValue(option.id.toLowerCase());
    } else {
      this.removeValue(option.id.toLowerCase());
    }
  }

  renderBadge(q) {
    try {
      if (
        typeof q.Badge !== 'undefined' &&
        q.Badge != null &&
        q.Badge.length > 1
      ) {
        return (
          <div className={'question-badge'}>
            <span>{q.Badge}</span>
          </div>
        );
      }
    } catch (e) {}
    return null;
  }

  easyCompare(v1, v2, only_left_to_right = false) {
    if (v1 && v2) {
      if (Array.isArray(v1)) {
        v1 = v1.join(',');
      }
      if (Array.isArray(v2)) {
        v2 = v2.join(',');
      }
      v1 = v1
        .toString()
        .replace(/\./g, '')
        .replace(/\_/g, '')
        .replace(/\-/g, '')
        .toLowerCase();
      v2 = v2
        .toString()
        .replace(/\./g, '')
        .replace(/\_/g, '')
        .replace(/\-/g, '')
        .toLowerCase();
      if (v1.length > 0 && v2.length > 0) {
        if (v1.includes(v2) || (!only_left_to_right && v2.includes(v1))) {
          return true;
        }
      }
    }
    return false;
  }

  hasValue(valset) {
    let val = this.qCtrl().getValueForQuestion(this.question());
    if (val != null) {
      if (!Array.isArray(val)) {
        val = [val];
      }
      if (
        this.easyCompare(
          _.cloneDeep(val)
            .filter(
              (f) =>
                typeof f === 'string' ||
                f.checked == true ||
                (typeof f.checked === 'undefined' &&
                  typeof f.id !== 'undefined')
            )
            .map((r) =>
              typeof r === 'string'
                ? r
                : typeof r.id === 'string'
                ? r.id.toLowerCase()
                : r.id
            ),
          valset,
          true
        )
      ) {
        return true;
      }
    } else {
      const vorauswahl = this.qCtrl().getVorauswahlforQuestion(this.question());
      if (vorauswahl != null) {
        if (Array.isArray(vorauswahl)) {
          if (
            this.easyCompare(
              _.cloneDeep(vorauswahl).map((r) =>
                typeof r === 'string' ? r.toLowerCase() : r
              ),
              valset,
              true
            )
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  renderOptions(options = null, level = 0) {
    const list = [];
    let opts = options ? options : this.state.options;
    if (opts) {
      opts = this.filterOptionsBySearch(opts);

      let columnWidth = 12;
      if (this.question().gridableElements === true) {
        columnWidth = 4;
      }

      const t = this.props.t;

      for (const o of opts) {
        const id = o.id ? o.id.toLowerCase() : o._id ? o._id.toLowerCase() : o;
        list.push(
          <div md={columnWidth} className={'p-1 option'}>
            <div className={"flex items-center mb-1"}>
              <Input
                disabled={this.isFinished()}
                type={'checkbox'}
                className={"w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"}
                checked={this.hasValue(id)}
                onChange={(e) => this.onOptionChanged(e, o)}
                name={this.questionName()}
              />
              <label className={"ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"}>{o.text ? t(o.text) : t(o.label)}</label> {this.renderBadge(o)}
            </div>
            <div className={'child-options level-' + level}>
              {o.children && this.renderOptions(o.children, level + 1)}
            </div>
          </div>
        );
      }
    }

    return <div className={'option-containers'}>{list}</div>;
  }

  hasReset() {
    return true;
  }

  render() {
    return (
      <div fluid={true}>
        {this.isSearchable() && this.renderSearchbar()}
        <div>
          <div className={'p-1'} md={12}>
            <div
              className={
                'p-1 multiple-choice-container ' +
                (this.hasError() ? 'error' : '')
              }
            >
              {this.renderOptions()}
              {this.renderErrors()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

QuestionMultipleChoice.propTypes = {};

export default withTranslation()(QuestionMultipleChoice);
