import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaLessThan } from 'react-icons/fa';
import QuestionPageContainer from "./QuestionPageContainer";
import SamplePageContainer from "./SamplePageContainer";

class FormularSite extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    qCtrl() {
        return this.props.qctrl;
    }


    render() {
        const isFullscreen = this.qCtrl().isFullscreenQuestion();

        return <div className={"formularsite p-0 container mx-auto grid grid-cols-12"} fluid={true}>
                    <div id={"questionwrapper"} className={"questionwrapper p-5 col-span-4 grid bg-yellow-400"} md={(isFullscreen) ? 12 : 5}>
                        <QuestionPageContainer {...this.props}></QuestionPageContainer>
                    </div>
                    {!isFullscreen && <div className={"samplecontainer p-4 col-span-8 grid bg-black"} md={7}>
                        <SamplePageContainer {...this.props}></SamplePageContainer>
                    </div>}
            </div>
        ;
    }
};

FormularSite.propTypes = {

};


export default FormularSite;
